@font-face {
  font-family: 'PP Formula Condensed';
  src: url('/assets/fonts/PP-Formula-Condensed/PP-Formula-Condensed-Bold-700.ttf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'PP Formula Condensed';
  src: url('/assets/fonts/PP-Formula-Condensed/PP-Formula-Condensed-Regular-400.ttf')
    format('opentype');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'PP Formula Condensed';
  src: url('/assets/fonts/PP-Formula-Condensed/PP-Formula-Condensed-Ultralight-100.ttf')
    format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('/assets/fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed Medium';
  src: url('/assets/fonts/Barlow_Condensed/BarlowCondensed-Medium.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed SemiBold';
  src: url('/assets/fonts/Barlow_Condensed/BarlowCondensed-SemiBold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed Bold';
  src: url('/assets/fonts/Barlow_Condensed/BarlowCondensed-Bold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Thin';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Thin.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Thin Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-ThinItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin ExtraLight';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-ExtraLight.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin ExtraLight Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-ExtraLightItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Light';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Light.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Light Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-LightItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Regular.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Italic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Medium';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Medium.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Medium Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-MediumItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin SemiBold';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-SemiBold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin SemiBoldItalic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Bold';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Bold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Bold Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-BoldItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin ExtraBold';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-ExtraBold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin ExtraBoldItalic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Black';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-Black.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Franklin Black Italic';
  src: url('/assets/fonts/Libre_Franklin/static/LibreFranklin-BlackItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Thin';
  src: url('/assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Thin Italic';
  src: url('/assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins ExtraLight';
  src: url('/assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins ExtraLight Italic';
  src: url('/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Light';
  src: url('/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Light Italic';
  src: url('/assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Italic';
  src: url('/assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Medium Italic';
  src: url('/assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins SemiBoldItalic';
  src: url('/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Bold Italic';
  src: url('/assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins ExtraBold';
  src: url('/assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins ExtraBoldItalic';
  src: url('/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Black';
  src: url('/assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Black Italic';
  src: url('/assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Italic';
  src: url('/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Medium';
  src: url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('/assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('/assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Italic';
  src: url('/assets/fonts/Playfair_Display/PlayfairDisplay-Italic-VariableFont_wght.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Medium';
  src: url('/assets/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Regular';
  src: url('/assets/fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display SemiBold';
  src: url('/assets/fonts/Playfair_Display/static/PlayfairDisplay-SemiBold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display Bold';
  src: url('/assets/fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/Helvetica_Neue/HelveticaNeue Medium.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('/assets/fonts/Helvetica_Neue/HelveticaNeue Bold.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('/assets/fonts/Helvetica_Neue/HelveticaNeue Light.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Thin';
  src: url('/assets/fonts/Helvetica_Neue/HelveticaNeue Thin.ttf')
    format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Italic';
  src: url('/assets/fonts/Helvetica_Neue/HelveticaNeueIt.ttf')
    format('truetype');
  font-style: italic;
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Italic';
  src: url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/assets/fonts/Neue_Montreal/NeueMontreal-Regular.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/assets/fonts/Neue_Montreal/NeueMontreal-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0px;
  right: 5px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%; /* ~10px, curious why? see this: https://engageinteractive.co.uk/blog/em-vs-rem-vs-px */
  overflow-y: scroll;
}

/* @media (min-width: 120em) {
  html {
    font-size: 68.75%;
  }
}
*/

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.3);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.3);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.3);
}

/* Wallet Modal */
.web3modal-modal-lightbox {
  z-index: 100 !important;
}
.web3modal-modal-card {
  font-family: 'Poppins';
  border-radius: 20px;
  width: 550px !important;
  max-width: 550px !important;
}
.web3modal-modal-card > a {
  text-decoration: none;
}
.web3modal-modal-card .web3modal-provider-wrapper {
  border: none;
}
.web3modal-modal-card .web3modal-provider-container {
  height: 85px;
  width: 95px;
  padding: 0;
}
.connect-sub-title {
  font-size: 10px;
}
.web3modal-modal-card .web3modal-modal-card-footer {
  width: 100%;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 16.8px;
  color: rgba(0, 0, 0, 0.5);
}
.web3modal-modal-card .web3modal-modal-card-header {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16.8px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 60px;
}
.web3modal-modal-card .web3modal-modal-card-header .title {
  font-size: 36px;
  color: #000;
  margin-bottom: 15px;
}
.web3modal-modal-card .web3modal-modal-card-footer a {
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.5);
}
.web3modal-modal-card .web3modal-provider-wrapper {
  width: 320px !important;
  padding: 0 50px;
  margin: 20px 0;
}
.web3modal-modal-card div:nth-child(2) {
  display: flex;
  align-items: flex-end;
}
.web3modal-provider-name {
  display: block !important;
}
.web3modal-modal-card div:nth-child(3) {
  display: flex;
  align-items: flex-start;
  border-left: 1px solid #000;
}
.web3modal-modal-card .web3modal-provider-description {
  display: none !important;
}
.web3modal-modal-card .web3modal-provider-name {
  font-size: 14px;
  padding-top: 14px;
}

/* Custom CSS Styling */

.container {
  width: 1100px;
}

.explore-container {
  min-width: 570px;
}

.profile-nft {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.profile-nft-column {
  flex: 33%;
  max-width: 33%;
  padding: 0 20px 40px 20px;
}

.profile-tab {
  width: 1020px;
  border-bottom: 0.5px solid #dadedf;
  padding: 0 0 10px 10px;
}

.profile-tab > a {
  margin: 0 50px 0 -10px;
}

.featured-grid {
  margin-left: 0;
}

.popover:after,
.popover::after {
  position: absolute;
  top: 9px;
  left: -12px;
  content: '';
  width: 0;
  height: 0;
  border-right: solid 12px #f4f4f4;
  border-bottom: solid 12px transparent;
  border-top: solid 12px transparent;
}

.left-popover:after,
.left-popover::after {
  position: absolute;
  top: 9px;
  right: -12px;
  content: '';
  width: 0;
  height: 0;
  border-left: solid 12px #f4f4f4;
  border-bottom: solid 12px transparent;
  border-top: solid 12px transparent;
}

.bottom-popover:after,
.bottom-popover::after {
  position: absolute;
  top: 9px;
  right: -12px;
  content: '';
  width: 0;
  height: 0;
  border-bottom: solid 12px #fff;
  border-left: solid 12px transparent;
  border-top: solid 12px transparent;
  border-right: solid 12px transparent;
}

.smooth-progress-bar {
  transition: width 2s;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.fadeIn {
  opacity: 1;
  transition: opacity 1s 1s;
}
/*
  ##Device = All n non-desktop
*/
@media (min-width: 320px) and (max-width: 1024px) {
  .go-top {
    right: 50px !important;
    bottom: 50px !important;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 900px to 1024px
*/

@media (min-width: 901px) and (max-width: 1150px) {
  .profile-nft-column {
    flex: 50%;
    max-width: 50%;
  }

  .container {
    width: 98vw;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 900px) {
  .profile-nft-column {
    flex: 50%;
    max-width: 50%;
  }

  .container {
    width: 88vw;
  }

  .profile-tab {
    width: 90vw;
    margin: auto;
    margin-bottom: 40px;
    border-bottom: 0.5px solid #dadedf;
    padding: 0 0 10px 10px;
  }
}

.react-tagsinput {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  height: 48px;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}

.react-tagsinput--focused {
  border-color: #f5f5f5;
}

.react-tagsinput-tag {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
  color: #000;
  display: inline-block;
  font-family: sans-serif;
  font-size: 16px;
  margin-right: 8px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
  color: #909090;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  outline: none;
  margin-top: 10px;
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 320px) and (max-width: 767px) {
  .profile-nft-column {
    flex: 100%;
    max-width: 100%;
    padding: 0;
  }

  .explore-container {
    min-width: 90vw;
  }

  .profile-tab {
    width: 89vw;
    text-align: center;
  }

  .profile-tab > a {
    margin: 0 10px 0 10px;
  }

  .container {
    width: 88vw;
  }

  .feed-explore,
  .feed-explore-image {
    width: 88vw !important;
    max-width: 400px;
  }

  .feed-explore {
    margin-top: 10px !important;
    margin-bottom: 90px !important;
  }

  .explore-activity-item {
    max-width: 87vw;
  }
}

.show-more {
  line-height: 26px;
}

.show-more-a {
  text-decoration: none;
  color: #00a3ff;
}

.landing-view-all {
  width: 90%;
  margin: 0 auto;
}
.landing-section-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 73px;
}

.blog-content-frame {
  width: 640px;
  height: 480px;
}

.sellection-collection-item {
  cursor: pointer;
}

.sellection-collection-item:hover {
  background-color: #e5e5e5;
}

@media screen and (min-width: 2100px) {
  .landing-view-all {
    width: 1641px;
  }
}
@media (min-width: 1420px) and (max-width: 2100px) {
  .landing-view-all {
    width: 1050px;
  }
}
@media (min-width: 1050px) and (max-width: 1420px) {
  .landing-view-all {
    width: 880px;
  }
}
@media (min-width: 750px) and (max-width: 1050px) {
  .landing-view-all {
    width: 545px;
  }
}
@media (max-width: 750px) {
  .landing-view-all {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .blog-content-frame {
    width: 100%;
    height: 100%;
  }
  .web3modal-modal-card {
    display: block !important;
  }
  .web3modal-modal-card .web3modal-provider-wrapper {
    width: 150px !important;
    margin: auto;
  }
  .web3modal-modal-card .web3modal-provider-container {
    height: 120px;
  }
  .blog-title-mobile {
    font-size: 55px !important;
    line-height: 100% !important;
  }
  .blog-iframe-mobile {
    width: 100%;
    height: auto;
  }
}

@media (pointer: none), (pointer: coarse) {
  .web3modal-modal-card > div {
    display: block !important;
    width: 100% !important;
  }
  .web3modal-modal-card .web3modal-provider-wrapper {
    width: 100% !important;
    display: none;
  }
}

/*responsive for home page*/
.home-page-container {
  margin: auto;
  width: 1700px;
}

@media screen and (max-width: 1900px) {
  .home-page-container {
    padding: 0 160px;
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .home-page-container {
    padding: 0 90px 0 120px;
    width: 100%;
  }
}
@media screen and (max-width: 1232px) {
  .home-page-container {
    padding: 0 50px;
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 1231px) {
  .home-page-container {
    padding: 0 22px;
    width: 100vw;
  }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
/* Standard syntax */
@keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
.loading {
  animation: spinner 1s linear 0s infinite;
  -webkit-animation: spinner 1s linear 0s infinite;
  -moz-animation: spinner 1s linear 0s infinite;
  -o-animation: spinner 1s linear 0s infinite;
}
